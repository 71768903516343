<!--
 * @Author: Link
 * @Date: 2021-01-11 10:27:10
 * @LastEditTime: 2021-01-15 09:56:46
-->
<template>
  <div class="related-area">
    <div class="searchContainer">
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">地区</span>
          <el-input class="searchInput" v-model="searchInput" placeholder="请输入地区" clearable> </el-input>
        </el-col>
        <el-col :span="8" :offset="8">
          <el-button
            type="info"
            @click="
              () => {
                searchInput = ''
                pagination.page = 1
                getData()
              }
            "
            >重置</el-button
          >
          <el-button
            type="primary"
            @click="
              pagination.page = 1
              getData()
            "
          >
            搜索</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="header">
      <div class="title">关联地区列表</div>
      <el-button type="success" @click="dialog.show = true">添加</el-button>
    </div>
    <el-table :data="table.list" style="margin-top:20px">
      <el-table-column
        align="center"
        v-for="col in Object.keys(table.label)"
        :key="col"
        :label="table.label[col]"
        :prop="col"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button @click="action('delete', row)" type="text" size="small" class="deleteBtnText">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pagination.page"
        :page-size="pagination.size"
        :total="pagination.total"
        @current-change="
          v => {
            pagination.page = v
            getData()
          }
        "
        @size-change="
          v => {
            pagination.size = v
            pagination.page = 1
            getData()
          }
        "
        layout="total, sizes, prev, pager, next, jumper"
        class="pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible="dialog.show"
      title="添加关联地区"
      @close="dialog.show = false"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-table :data="dialog.district" highlight-current-row @current-change="v => (dialog.selected = v)">
        <el-table-column label="地区" prop="districtTitle" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="dialog.acting">确 定</el-button>
      </div>
    </el-dialog>
    <bm-deleteItem
      url="/boom-center-wechat-service/sysAdmin/wx/media/platform/associate/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
export default {
  props: ['wxPid'],
  data() {
    return {
      searchInput: '',
      timestamp: 0,
      dialog: {
        show: false,
        acting: false,
        district: [],
        selected: {}
      },
      table: {
        label: {
          name: '地区'
        },
        list: []
      },
      pagination: {
        total: 1,
        size: 10,
        page: 1
      }
    }
  },
  created() {
    this.getData()
    this.getDistrict()
  },
  methods: {
    action(type, row) {
      if (type === 'delete') {
        this.timestamp = new Date().getTime()
        sessionStorage['deleteItemId'] = row.id
      }
    },
    successDelete() {
      this.$message({
        message: '删除成功',
        type: 'success'
      })
      this.getData()
    },
    getDistrict() {
      this.$http.get('/boom-center-product-service/sysAdmin/businessDistrict/list').then(res => {
        this.dialog.district = res.list
      })
    },
    getData() {
      let params = `wxMediaPlatformId=${this.wxPid}&businessDistrictName=${this.searchInput}&page=${this.pagination.page}&size=${this.pagination.size}`
      this.$http
        .get(`/boom-mix-biz-service/sysAdmin/wx/media/platform/associate/pageAssociateBusinessDistrictBy?` + params)
        .then(res => {
          this.table.list = res.records
          this.pagination.total = parseInt(res.total)
        })
    },
    submit() {
      if (this.dialog.selected.id) {
        this.dialog.acting = true
        this.$http
          .post('/boom-center-wechat-service/sysAdmin/wx/media/platform/associate', {
            associateId: this.dialog.selected.id,
            associateType: 2,
            wxMediaPlatformId: this.wxPid
          })
          .then(res => {
            if (!res.msg) {
              this.$message({
                message: res,
                type: 'success'
              })
              this.getData()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
          .finally(() => {
            this.dialog.acting = false
            this.dialog.show = false
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.el-button--primary {
  color: #fff !important;
  background-color: #1975ff !important;
  border-color: #1975ff !important;
  margin-left: 20px;
}
.el-button--warning {
  color: #fff !important;
  background-color: #e6a23c !important;
  border-color: #e6a23c !important;
}
.action {
  display: flex;
  .item {
    padding: 0 5px;
    cursor: pointer;
    user-select: none;
    &:active {
      filter: brightness(60%);
    }
  }
}
</style>
