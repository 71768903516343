var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"related-area"},[_c('div',{staticClass:"searchContainer"},[_c('el-row',{staticClass:"query",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('span',{staticClass:"searchText"},[_vm._v("地区")]),_c('el-input',{staticClass:"searchInput",attrs:{"placeholder":"请输入地区","clearable":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('el-col',{attrs:{"span":8,"offset":8}},[_c('el-button',{attrs:{"type":"info"},on:{"click":function () {
              _vm.searchInput = ''
              _vm.pagination.page = 1
              _vm.getData()
            }}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.pagination.page = 1
            _vm.getData()}}},[_vm._v(" 搜索")])],1)],1)],1),_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("关联地区列表")]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){_vm.dialog.show = true}}},[_vm._v("添加")])],1),_c('el-table',{staticStyle:{"margin-top":"20px"},attrs:{"data":_vm.table.list}},[_vm._l((Object.keys(_vm.table.label)),function(col){return _c('el-table-column',{key:col,attrs:{"align":"center","label":_vm.table.label[col],"prop":col}})}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('el-button',{staticClass:"deleteBtnText",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.action('delete', row)}}},[_vm._v(" 删除 ")])]}}])})],2),_c('div',{staticClass:"pagination"},[_c('el-pagination',{staticClass:"pagination",attrs:{"current-page":_vm.pagination.page,"page-size":_vm.pagination.size,"total":_vm.pagination.total,"layout":"total, sizes, prev, pager, next, jumper"},on:{"current-change":function (v) {
          _vm.pagination.page = v
          _vm.getData()
        },"size-change":function (v) {
          _vm.pagination.size = v
          _vm.pagination.page = 1
          _vm.getData()
        }}})],1),_c('el-dialog',{attrs:{"visible":_vm.dialog.show,"title":"添加关联地区","append-to-body":"","close-on-click-modal":false},on:{"close":function($event){_vm.dialog.show = false}}},[_c('el-table',{attrs:{"data":_vm.dialog.district,"highlight-current-row":""},on:{"current-change":function (v) { return (_vm.dialog.selected = v); }}},[_c('el-table-column',{attrs:{"label":"地区","prop":"districtTitle","align":"center"}})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.dialog.acting},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1),_c('bm-deleteItem',{attrs:{"url":"/boom-center-wechat-service/sysAdmin/wx/media/platform/associate/","timestamp":_vm.timestamp},on:{"successDelete":_vm.successDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }